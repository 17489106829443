import { useStaticQuery, graphql } from 'gatsby';

export const useCoursesMetadata = () => {
  const data = useStaticQuery(
    graphql`
      query SiteMetaData {
        courseThumbnails: allFile(filter: {relativeDirectory: {regex: "/courses/thumbnails/"}}) {
          edges {
            node {
              name
              publicURL
              childImageSharp {
                fluid(quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        allCoursesJson: allCoursesJson {
          edges {
            node {
              id
              general {
                courseName
                thumbnail
                featured
                applications {
                  endDate
                  startDate
                }
                subscriptionLink
              }
              description {
                scholarity
                imageUrl {
                  name
                  publicURL
                }
              }
            }
          }
        }
        # all pages that were created via actions.createPage()
        pathsToCourses: allSitePage(filter: {isCreatedByStatefulCreatePages: {eq: false}, path: {regex: "/cursos/"}}) {
          edges {
            node {
              path
              context {
                jsonFileId
              }
            }
          }
        }
      }
    `
  );

  return {
    courseThumbnails: data.courseThumbnails,
    allCoursesJson: data.allCoursesJson,
    pathsToCourses: data.pathsToCourses,
  };
};
import moment from 'moment';
import 'moment/locale/pt';
moment.locale('pt');

const getPathToCoursePage = (edge, pathsToCourses) => {
  const { id } = edge.node;
  const result = pathsToCourses.edges.find(edge => edge.node.context.jsonFileId === id);
  return result ? result.node.path : '';
};

const isCourseOpenForSignUp = edge => {
  const { general: { applications }} = edge.node;
  return isSignUpOpen(applications);
};

const isCourseClosedForSignUp = edge => {
  const { general: { applications }} = edge.node;
  return !isSignUpOpen(applications);
};

const isSignUpOpen = ({ startDate, endDate }) => {
  const today = moment();
  const insideLowerLimit = today.isAfter(moment(startDate, "YYYY-MM-DDTHH:mm"), 'minute');
  const insideUpperLimit = today.isBefore(moment(endDate, "YYYY-MM-DDTHH:mm"), 'minute');

  return insideLowerLimit && insideUpperLimit;
};

const getEndDateLabel = value => {
  const timeSegment = moment(value, "YYYY-MM-DDTHH:mm").format('D [de] MMMM [de] YYYY');
  return `candidaturas até ${timeSegment}`;
};

const getImageNodeByName = (name, images) => {
  const edge = images.edges.find(edge => {
    const a = String(name).toLowerCase();
    const b = String(edge.node.name).toLowerCase();
    return a === b;
  });

  if (edge) {
    return edge.node;
  }

  return null;
};

export {
  getEndDateLabel,
  getImageNodeByName,
  isSignUpOpen,
  getPathToCoursePage,
  isCourseOpenForSignUp,
  isCourseClosedForSignUp,
};
